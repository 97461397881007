import { Box, Link, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import theme from '../theme';

const Footer = () => {
    const [versionInfo, setVersionInfo] = useState({ release: 'unknown', version: 'unknown' });

    useEffect(() => {
      fetch('/version.txt')
        .then(response => response.text())
        .then(text => {
          const lines = text.split('\n');
          const release = lines.find(line => line.startsWith('release:')).split(':')[1].trim();
          const version = lines.find(line => line.startsWith('version:')).split(':')[1].trim();
          setVersionInfo({ release, version });
        })
        .catch(error => console.error('Failed to fetch version info:', error));
    }, []);

    return (
        <Box
          component="footer"
          sx={{
            width: '100vw',
            backgroundColor: '#333',
            color: '#fff',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px 20px',
            [theme.breakpoints.down('sm')]: {
              padding: '5px 10px',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              justifyContent: 'center',
              gap: '10px',
            },
          }}
        >
          <Typography
            variant="body2"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Box component="span" sx={{ marginRight: 1 }}>📍</Box>
            Made in London, United Kingdom
          </Typography>
          <Typography
            variant="body2"
            sx={{
              display: 'flex',
              gap: '100px',
              flex: 1,
              justifyContent: 'right',
              textAlign: 'right',
              [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
                textAlign: 'center',
                marginRight: 0,
                gap: '10px',
              },
            }}
          >
            <Box sx={{ [theme.breakpoints.up('sm')]: { marginRight: 2 } }}>
              <Link href="#" color="inherit" underline="hover">Cookie Settings</Link>
            </Box>
          </Typography>
          <Typography
            variant="body2"
            sx={{
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            © ZannaLabs 2024 | App version {versionInfo.release}-{versionInfo.version}
          </Typography>
        </Box>
    );
};

export default Footer;