import { Box, Button, Link, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import mainImage from '../assets/2181012-200.png';
import logoImage from '../assets/header_trimmed.jpeg';
import gcloudLogo from '../assets/vendors/gcloud.png';
import openAILogo from '../assets/vendors/openai.png';
import Footer from './Footer';

const Landing = ({setLoading}) => {
  const navigate = useNavigate();

  const handleNavigateToLogin = () => {
    setLoading(true);
    navigate('/login');
    setLoading(false);
  };

  return (
    <>
      {/* Parent Container */}
      <Box
        display="flex"
        flexDirection="column"
        height="100vh"
        width="100vw"
        overflow="auto"
      >
        {/* Header Section */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          position="sticky"
          width="100%"
          p={1}
          bgcolor="white"
        >
          <Box display="flex" alignItems="center">
            <img
              src={logoImage}
              alt="Logo"
              style={{ maxWidth: '186px', maxHeight: '180px', marginLeft: '20px' }}
            />
            <Button
              variant="text"
              component={Link}
              href="https://clausible.notion.site/About-Us-94b631715b034cc3801db1e6719920ad?pvs=4"
              target="_blank"
              rel="noopener"
              sx={{
                ml: 2,
                color: '#000',
                textTransform: 'none',
                '&:hover': {
                  color: '#888',
                  backgroundColor: 'transparent',
                },
              }}
            >
              ABOUT US
            </Button>
          </Box>
          <Button
            variant="text"
            onClick={handleNavigateToLogin}
            sx={{
              color: '#000',
              fontWeight: 'bold',
              '&:hover': {
                color: '#888',
                backgroundColor: 'transparent',
              },
              marginRight: '20px',
            }}
          >
            Login
          </Button>
        </Box>

        {/* Main Content Section */}
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
          p={6}
          sx={{
            background:
              'linear-gradient(135deg, rgba(255,156,0,1) 0%, rgba(255,145,77,1) 0%, rgba(0,212,255,0) 100%)',
          }}
        >
          <Box
            textAlign="left"
            // sx={{ maxWidth: '1200px', width: '100%', marginLeft: '30px' }}
            p={6}
          >
            <Typography variant="h2" sx={{ fontWeight: 'bold', color: '#333' }}>
              Contracts, Clauses, Crystal Clear.
            </Typography>
            <Typography variant="h6" sx={{ color: '#666', maxWidth: '600px', mt: 2 }}>
              Legal AI for small businesses.
            </Typography>
            <Typography variant="h6" sx={{ color: '#666' }}>
              Create, sign, and manage contracts in minutes.
            </Typography>
          </Box>
          <Box>
            <img
              src={mainImage}
              alt="Main Img"
              style={{ maxWidth: '250px', maxHeight: '250px', marginRight: '50px' }}
            />
          </Box>
        </Box>

        {/* Partners/Vendors Section */}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={2}
          flexWrap="wrap"
          bgcolor="#f4f4f4"
        >
          <Typography variant="body1">
            Powered by:
          </Typography>
          <img
            src={openAILogo}
            alt="Vendor 2"
            style={{ maxWidth: '200px', maxHeight: '150px' }}
          />
          <img
            src={gcloudLogo}
            alt="google cloud"
            style={{ maxWidth: '250px', maxHeight: '200px' }}
          />
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default Landing;
